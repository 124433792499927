import React from 'react'
import {graphql, Link} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes} from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'

import {responsiveTitle1} from '../components/typography.module.css'
import {responsiveTitle2} from '../components/typography.module.css'

// Add “posts” to the GraphQL query
export const query = graphql`
  query CategoryTemplateQuery($id: String!) {

    category: sanityCategory(
      id: {eq: $id}
      ) {
        title
        emoji
        description
        slug {
          current
        }
      }

    posts: allSanityPost(
      filter: {categories: {elemMatch: {id: {eq: $id}}}, slug: { current: { ne: null } }, publishedAt: { ne: null }}
      sort: { fields: [publishedAt], order: DESC }
      ) {
      totalCount
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          categories {
            slug {
              current
            }
          }
        }
      }
    }

  }

`

const CategoryPostTemplate = props => {
  const {data = {}, errors} = props
  // Destructure the new posts property from props
  const {title, emoji, description} = data.category || {}

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  console.log(postNodes)

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Container>
        {errors && <GraphQLErrorList errors={errors} />}
        {!data.category && <p>No category data</p>}
        <h1 className={responsiveTitle1}><span>{emoji}</span> {title} <em className={responsiveTitle2}>({data.posts.totalCount})</em></h1>
        <p>{description}</p>
        {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
      </Container>
    </Layout>
  )
}

export default CategoryPostTemplate

